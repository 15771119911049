import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { colors, EMPTY_GAME } from '@constants';
import { GameContext } from '@context/game';
import { LevelsContext } from '@context/levels';
import { Game, Level } from '@types';
import { useState } from 'react';
import { useLocalStorage } from 'react-use';

import './App.scss';

import Routes from './Routes';

const theme = extendTheme({
  colors,
});

function App() {
  const [storeGame, setStoreGame] = useLocalStorage<Game>(`@EsquentaApp:Game`);

  const [game, setGame] = useState<Game>(storeGame || EMPTY_GAME);
  const [levels, setLevels] = useState<Level[]>([]);

  const updateGame = (updatedGame: Game) => {
    setStoreGame(updatedGame);
    setGame(updatedGame);
  };

  return (
    <GameContext.Provider value={{ game, setGame: updateGame }}>
      <LevelsContext.Provider value={{ levels, setLevels }}>
        <ChakraProvider theme={theme}>
          <div className="App">
            <Routes />
          </div>
        </ChakraProvider>
      </LevelsContext.Provider>
    </GameContext.Provider>
  );
}

export default App;
