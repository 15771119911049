export default function CardGameIcon() {
  return (
    <svg
      width="504"
      height="616"
      viewBox="0 0 504 616"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="24" y="25" width="404" height="518" fill="#EB83B5" />
      <rect x="79" y="78" width="406" height="511" fill="#04B9FB" />
      <path
        d="M446.72 57.2867C443.751 41.224 435.256 26.708 422.715 16.2453C410.168 5.78693 394.36 0.0373333 378.027 0H70C51.4427 0.0208333 33.6507 7.40626 20.5267 20.5267C7.40694 33.6517 0.0213333 51.444 0 70V490C0.0364587 506.333 5.78641 522.14 16.2453 534.688C26.7089 547.23 41.224 555.724 57.2867 558.693C60.2555 574.756 68.7503 589.272 81.292 599.735C93.8389 610.193 109.647 615.943 125.98 615.98H433.98C452.537 615.959 470.329 608.574 483.453 595.453C496.573 582.328 503.959 564.536 503.98 545.98V126.007C503.949 109.673 498.204 93.8613 487.751 81.3133C477.292 68.7664 462.777 60.2667 446.715 57.292L446.72 57.2867ZM56 125.98V529.42C47.8333 526.545 40.76 521.212 35.7453 514.155C30.7349 507.097 28.0265 498.66 28.0005 490.004V70.004C28.0109 58.8685 32.4432 48.192 40.3131 40.316C48.188 32.4463 58.8651 28.0139 70.0011 28.0035H378.001C386.657 28.0295 395.094 30.7379 402.152 35.7483C409.214 40.7639 414.548 47.8368 417.422 56.0029H125.996C107.438 56.0238 89.6464 63.4092 76.5224 76.5296C63.4027 89.6547 56.0171 107.447 55.9957 126.003L56 125.98ZM476 546.007C475.99 557.142 471.557 567.819 463.687 575.695C455.813 583.564 445.135 587.997 433.999 588.007H125.999C114.864 587.997 104.187 583.565 96.3115 575.695C88.4417 567.82 84.0093 557.143 83.9989 546.007V126.007C84.0094 114.871 88.4416 104.195 96.3115 96.3187C104.186 88.4489 114.863 84.0165 125.999 84.0061H433.999C445.135 84.0166 455.811 88.4488 463.687 96.3187C471.557 104.194 475.99 114.871 476 126.007V546.007Z"
        fill="#1F1F1F"
      />
      <path
        d="M180.52 161.734L159.525 119.734C156.895 115.322 152.135 112.619 146.999 112.619C141.864 112.619 137.104 115.322 134.473 119.734L113.479 161.734C111.692 165.067 111.328 168.978 112.473 172.583C113.619 176.187 116.171 179.176 119.552 180.869C122.932 182.562 126.854 182.812 130.421 181.567C133.994 180.327 136.911 177.692 138.505 174.265L146.989 157.306L155.463 174.265C158.922 181.181 167.333 183.978 174.25 180.52C177.573 178.859 180.093 175.947 181.271 172.421C182.442 168.9 182.166 165.057 180.505 161.734L180.52 161.734Z"
        fill="#1F1F1F"
      />
      <path
        d="M440.267 491.48C433.35 488.021 424.944 490.824 421.48 497.735L413.006 514.694L404.522 497.735C401.063 490.818 392.652 488.021 385.735 491.48C378.818 494.938 376.021 503.35 379.48 510.266L400.474 552.266C402.849 557.006 407.698 560.001 413 560.001C418.302 560.001 423.152 557.006 425.526 552.266L446.521 510.266C448.188 506.944 448.459 503.1 447.287 499.574C446.115 496.053 443.589 493.142 440.266 491.48L440.267 491.48Z"
        fill="#1F1F1F"
      />
      <line
        x1="166"
        y1="295.5"
        x2="397.054"
        y2="295.5"
        stroke="#1F1F1F"
        stroke-width="25"
      />
      <line
        x1="166"
        y1="391.5"
        x2="397.054"
        y2="391.5"
        stroke="#1F1F1F"
        stroke-width="25"
      />
      <line
        x1="166"
        y1="345.5"
        x2="397.054"
        y2="345.5"
        stroke="#1F1F1F"
        stroke-width="25"
      />
    </svg>
  );
}
