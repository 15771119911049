import { Box, Center, Flex, Heading, Icon, Stack } from '@chakra-ui/react';
import EsquentaButton from '@components/EsquentaButton';
import { HeaderActionsTypes } from '@components/HeaderActions';
import LevelCard from '@components/LevelCard';
import { GameModes, Paths, PhraseLevel } from '@constants';
import { useGame } from '@context/game';
import { useLevels } from '@context/levels';
import getStrings from '@strings';
import EsquentaAPI from '@utils/EsquentaAPI';
import { useEffect, useMemo, useState } from 'react';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { IoPlay } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import Footer from '../../components/Footer';
import Header from '../../components/Header';

import './levels.scss';

const buttonIcons = {
  back: <Icon as={IoMdArrowRoundBack} />,
  play: <Icon as={IoPlay} />,
};

export default function Levels() {
  const { game, setGame } = useGame();
  const { levels, setLevels } = useLevels();
  const navigate = useNavigate();

  const strings = getStrings(game.lang);

  const [lastGameMode, setLastGameMode] = useState<GameModes>();

  const splittedLevels = useMemo(() => {
    const toSplit = [...levels];
    const splitted = [];
    while (toSplit.length) {
      splitted.push(toSplit.splice(0, 3));
    }

    return splitted;
  }, [levels]);

  const startGame = () => {
    if (!game) {
      return;
    }

    if (!game.levels.length) {
      Swal.fire({
        text: strings.noLevelsAlert,
        icon: 'warning',
      });
      return;
    }

    navigate(Paths.PLAY);
  };

  useEffect(() => {
    (async () => {
      if (!game.id) {
        const newGame = await EsquentaAPI.createGame();
        setGame({ ...game, ...newGame });
      } else {
        try {
          const api = new EsquentaAPI(game);
          await api.getGame();
        } catch (err) {
          alert('lalala');
          setGame({ ...game, id: 0 });
        }
      }
    })();
  });

  useEffect(() => {
    (async () => {
      if (lastGameMode === game.gameMode) {
        return;
      }

      const apiLevels = await EsquentaAPI.getLevels(game.gameMode);

      setLastGameMode(game.gameMode);
      setLevels(apiLevels);
    })();
  }, [levels, game.gameMode]);

  const toggleLevel = (level: PhraseLevel) => {
    const levels = [...game.levels];

    const levelIndex = levels.indexOf(level);

    if (levelIndex === -1) {
      levels.push(level);
    } else {
      levels.splice(levelIndex, 1);
    }

    setGame({ ...game, levels });
  };

  return (
    <>
      <Header
        actions={[
          HeaderActionsTypes.AUTO_COMMIT,
          HeaderActionsTypes.GAME_MODE,
          HeaderActionsTypes.EXIT,
        ]}
      />
      <Box
        className="level-selector"
        maxWidth="fit-content"
        width="100%"
        margin="auto"
        borderColor="esquentaBlue"
        zIndex={990}
      >
        <Center>
          <Heading className="playerTitle" color="esquentaBlue">
            {strings.levelSelector}
          </Heading>
        </Center>
        <Box py={0} px={5}>
          <Stack
            direction="column"
            textAlign="center"
            justify="center"
            spacing={{ base: 4, lg: 10 }}
            py={4}
          >
            {splittedLevels.map((levels) => (
              <Stack
                direction={{ base: 'column', md: 'row' }}
                textAlign="center"
                justify="center"
                spacing={{ base: 4, lg: 10 }}
              >
                {levels.map((level, index) => {
                  const { id } = level;
                  return (
                    <LevelCard
                      level={level}
                      isSelected={game.levels.includes(id as PhraseLevel)}
                      onClick={() => toggleLevel(id as PhraseLevel)}
                      key={`level-${index}`}
                    />
                  );
                })}
              </Stack>
            ))}
          </Stack>
          <Flex className="actionButtons">
            <EsquentaButton
              leftIcon={buttonIcons.back}
              onClick={() => navigate(Paths.HOME)}
              colorType={'pink'}
            >
              {strings.back}
            </EsquentaButton>
            <EsquentaButton
              leftIcon={buttonIcons.play}
              marginLeft="1em"
              onClick={startGame}
              colorType="blue"
            >
              {strings.startGame}
            </EsquentaButton>
          </Flex>
        </Box>
      </Box>
      <Footer />
    </>
  );
}
