export default function ReturnCardsIcon() {
  return (
    <svg
      width="504"
      height="616"
      viewBox="0 0 504 616"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="24" y="25" width="404" height="518" fill="#EB83B5" />
      <rect x="79" y="78" width="406" height="511" fill="#04B9FB" />
      <path
        d="M446.72 57.2867C443.751 41.224 435.256 26.708 422.715 16.2453C410.168 5.78693 394.36 0.0373333 378.027 0H70C51.4427 0.0208333 33.6507 7.40626 20.5267 20.5267C7.40694 33.6517 0.0213333 51.444 0 70V490C0.0364587 506.333 5.78641 522.14 16.2453 534.688C26.7089 547.23 41.224 555.724 57.2867 558.693C60.2555 574.756 68.7503 589.272 81.292 599.735C93.8389 610.193 109.647 615.943 125.98 615.98H433.98C452.537 615.959 470.329 608.574 483.453 595.453C496.573 582.328 503.959 564.536 503.98 545.98V126.007C503.949 109.673 498.204 93.8613 487.751 81.3133C477.292 68.7664 462.777 60.2667 446.715 57.292L446.72 57.2867ZM56 125.98V529.42C47.8333 526.545 40.76 521.212 35.7453 514.155C30.7349 507.097 28.0265 498.66 28.0005 490.004V70.004C28.0109 58.8685 32.4432 48.192 40.3131 40.316C48.188 32.4463 58.8651 28.0139 70.0011 28.0035H378.001C386.657 28.0295 395.094 30.7379 402.152 35.7483C409.214 40.7639 414.548 47.8368 417.422 56.0029H125.996C107.438 56.0238 89.6464 63.4092 76.5224 76.5296C63.4027 89.6547 56.0171 107.447 55.9957 126.003L56 125.98ZM476 546.007C475.99 557.142 471.557 567.819 463.687 575.695C455.813 583.564 445.135 587.997 433.999 588.007H125.999C114.864 587.997 104.187 583.565 96.3115 575.695C88.4417 567.82 84.0093 557.143 83.9989 546.007V126.007C84.0094 114.871 88.4416 104.195 96.3115 96.3187C104.186 88.4489 114.863 84.0165 125.999 84.0061H433.999C445.135 84.0166 455.811 88.4488 463.687 96.3187C471.557 104.194 475.99 114.871 476 126.007V546.007Z"
        fill="#1F1F1F"
      />
      <path
        d="M283.218 243.361C336.669 243.361 380 286.708 380 340.18C380 393.652 336.669 437 283.218 437C237.772 437 199.645 405.663 189.243 363.412L220.578 355.706C227.527 383.855 252.936 404.724 283.219 404.724C318.853 404.724 347.74 375.824 347.74 340.178C347.74 304.53 318.853 275.632 283.219 275.632C270.511 275.632 258.66 279.307 248.672 285.654C252.485 286.77 254.919 287.701 256.777 289.014C262.5 293.061 265.591 299.887 264.856 306.86C264.493 310.298 262.591 314.152 258.789 321.86L184 301.814L204.04 227C212.539 227.547 216.788 227.821 219.924 229.204C226.366 232.041 230.758 238.151 231.399 245.163C231.701 248.478 230.68 252.456 228.623 260.224C244.163 249.584 262.965 243.36 283.217 243.36L283.218 243.361Z"
        fill="#1F1F1F"
      />
      <path
        d="M147.423 114.687C165.967 114.687 181 129.756 181 148.344C181 166.932 165.967 182 147.423 182C131.656 182 118.428 171.107 114.819 156.42L125.69 153.741C128.101 163.526 136.917 170.78 147.423 170.78C159.786 170.78 169.808 160.734 169.808 148.343C169.808 135.951 159.786 125.905 147.423 125.905C143.014 125.905 138.902 127.183 135.437 129.389C136.76 129.777 137.605 130.101 138.249 130.557C140.235 131.964 141.307 134.337 141.052 136.761C140.926 137.956 140.266 139.296 138.947 141.975L113 135.007L119.953 109C122.901 109.19 124.375 109.285 125.463 109.766C127.698 110.752 129.222 112.876 129.444 115.314C129.549 116.466 129.195 117.849 128.481 120.549C133.873 116.851 140.396 114.687 147.422 114.687L147.423 114.687Z"
        fill="#1F1F1F"
      />
      <path
        d="M416.634 556.654C398.091 556.464 383.213 541.241 383.405 522.654C383.596 504.067 398.783 489.154 417.326 489.345C433.092 489.507 446.208 500.536 449.665 515.26L438.767 517.826C436.457 508.017 427.717 500.672 417.211 500.564C404.849 500.437 394.724 510.38 394.596 522.77C394.469 535.162 404.387 545.31 416.749 545.437C421.158 545.482 425.282 544.247 428.77 542.076C427.451 541.675 426.61 541.342 425.97 540.879C423.999 539.452 422.951 537.069 423.231 534.647C423.37 533.454 424.043 532.121 425.39 529.455L451.264 536.69L444.044 562.624C441.097 562.403 439.624 562.293 438.541 561.801C436.317 560.792 434.815 558.652 434.618 556.213C434.525 555.059 434.893 553.68 435.635 550.987C430.205 554.63 423.66 556.727 416.634 556.655L416.634 556.654Z"
        fill="#1F1F1F"
      />
    </svg>
  );
}
