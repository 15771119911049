import { Box, Flex, Image } from '@chakra-ui/react';
import HeaderActions, { HeaderActionsTypes } from '@components/HeaderActions';
import { colors, Paths } from '@constants';
import logo from '@img/logo-reduced.svg';
import { useNavigate } from 'react-router';

import './header.scss';

export type HeaderProps = {
  actions: HeaderActionsTypes[];
};

export default function Header({ actions }: HeaderProps) {
  const navigate = useNavigate();
  return (
    <Flex className="header" borderBottom={`1px solid ${colors.esquentaBlue}`}>
      <Box onClick={() => navigate(Paths.HOME)}>
        <Image className="hLogo" src={logo} />
      </Box>
      {actions?.length && <HeaderActions actions={actions} />}
    </Flex>
  );
}
