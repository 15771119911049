import { Button, ButtonProps } from '@chakra-ui/react';

interface EsquentaButtonProps extends ButtonProps {
  colorType: 'pink' | 'blue';
}

export default function EsquentaButton(props: EsquentaButtonProps) {
  const { colorType, ...restProps } = props;

  const colors = {
    pink: {
      color: 'esquentaPink',
      borderColor: 'esquentaPink',
      variant: 'outline',
      _hover: {
        bgColor: 'esquentaPink',
        color: 'esquentaBlack',
      },
    },
    blue: {
      color: 'esquentaBlue',
      borderColor: 'esquentaBlue',
      variant: 'outline',
      _hover: {
        bgColor: 'esquentaBlue',
        color: 'esquentaBlack',
      },
    },
  };

  const updatedProps = {
    ...colors[colorType],
    ...restProps,
  };

  return <Button {...updatedProps} />;
}
