import { Flex, Link, Text } from '@chakra-ui/react';
import { colors } from '@constants';
import { useGame } from '@context/game';
import getStrings from '@strings';

import Emoji from '../Emoji';

export default function Footer() {
  const { game } = useGame();
  const strings = getStrings(game.lang);
  return (
    <Flex
      as="footer"
      direction="column"
      alignItems="center"
      width="100%"
      alignSelf="flex-end"
      paddingY={{ base: '15px', lg: '30px' }}
      paddingX={{ base: '15%', lg: '10%' }}
      textAlign="center"
      fontWeight="400"
      fontSize="lg"
      margin="0"
      marginTop="10px"
      borderTop={`1px solid ${colors.esquentaBlue}`}
    >
      <Text size="15px" color="white">
        {strings.footerPrefix}
        <Emoji label="DESCUBRA" symbol="😏" />
        {strings.and}
        <Emoji label="café" symbol="☕" />
        {strings.by + ' '}
        <Link
          href="https://www.alexsanderlira.dev"
          color="white"
          fontWeight={'bold'}
          isExternal
        >
          Alê Lira
        </Link>
        !
      </Text>
    </Flex>
  );
}
