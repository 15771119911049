import { Flex, Text } from '@chakra-ui/react';
import { Phrase } from '@types';

type PhraseCardProps = {
  phrase: Phrase;
};

export default function PhraseCard(props: PhraseCardProps) {
  const { phrase } = props;

  const { content, comment } = phrase;

  return (
    <Flex direction="column">
      <Flex
        minW={0}
        // borderColor="rgba(255, 255, 255, 0.5)"
        // // border="1px solid"
        height="auto"
        width="100%"
        padding="1.25rem"
        direction="column"
      >
        {/* {title && (
          <Text
            textAlign="center"
            fontSize="2.2rem"
            width="100%"
            fontWeight="bold"
            paddingBottom="1.8rem"
          >
            {phrase.title}
          </Text>
        )} */}
        <Text fontSize="2.2rem" as="span">
          {content}
        </Text>
        {comment && (
          <Text
            textAlign="center"
            fontSize="1.2rem"
            width="100%"
            paddingTop="2.2rem"
          >
            {phrase.comment}
          </Text>
        )}
      </Flex>
    </Flex>
  );
}
