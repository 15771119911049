import { Box, Text, VStack, Button, Icon } from '@chakra-ui/react';
import { colors } from '@constants';
import { useGame } from '@context/game';
import getStrings from '@strings';
import { Level } from '@types';
import { ImCheckboxUnchecked, ImCheckboxChecked } from 'react-icons/im';

type LevelCardProps = {
  level: Level;
  isSelected: boolean;
  onClick: () => void;
};

export default function LevelCard(props: LevelCardProps) {
  const { level, isSelected, onClick } = props;
  const { game } = useGame();
  const strings = getStrings(game?.lang);

  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: 'center', md: 'flex-start' }}
      borderColor={level.color}
      bgColor={level.color}
      borderRadius={'xl'}
      width={{ base: '100%', lg: '25%' }}
      height={{ base: '100%', lg: '25%' }}
    >
      <Box py={4} px={3}>
        <Text fontWeight="bold" fontSize="2xl">
          {strings[level.id as keyof typeof strings]}
        </Text>
      </Box>
      <VStack bg={colors.esquentaBlack} py={4} borderBottomRadius={'xl'}>
        <Text fontSize="md" padding="0.5rem">
          {level.description}
        </Text>
        {/* <List spacing={3} textAlign="start" px={7} width="100%">
          {level.categories.map((category) => (
            <ListItem>
              <ListIcon as={HiOutlineFire} color={level.color} />
              {category}
            </ListItem>
          ))}
        </List> */}
        <Box w="80%" pt={3}>
          <Button
            w="full"
            borderColor={level.color}
            color={level.color}
            variant="outline"
            onClick={onClick}
            _hover={{
              color: level.color,
            }}
          >
            <Icon
              color={level.color}
              margin="5px 5px 5px 5px"
              fontSize="1.5rem"
              as={isSelected ? ImCheckboxChecked : ImCheckboxUnchecked}
            />
          </Button>
        </Box>
      </VStack>
    </Box>
  );
}
