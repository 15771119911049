import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  Heading,
  Icon,
} from '@chakra-ui/react';
import { HeaderActionsTypes } from '@components/HeaderActions';
import PhraseCard from '@components/PhraseCard';
import { Paths, PhraseCategory } from '@constants';
import { useGame } from '@context/game';
import { useLevels } from '@context/levels';
import getStrings from '@strings';
import { Level, Phrase } from '@types';
import EsquentaAPI from '@utils/EsquentaAPI';
import { useEffect, useMemo, useState } from 'react';
import {
  IoMdArrowRoundBack,
  IoMdArrowRoundForward,
  IoMdRefresh,
} from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

import Footer from '../../components/Footer';
import Header from '../../components/Header';

const buttonIcons = {
  back: <Icon as={IoMdArrowRoundBack} />,
  play: <Icon as={IoMdArrowRoundForward} />,
  refresh: <Icon as={IoMdRefresh} />,
};

export default function Play() {
  const { game } = useGame();
  const navigate = useNavigate();
  const { levels } = useLevels();
  const [phrase, setPhrase] = useState<Phrase | null>(null);
  const [commit, setCommit] = useState<number>(0);
  const [level, setLevel] = useState<Level>(levels[0]);
  const api = new EsquentaAPI(game);

  const strings = getStrings(game.lang);

  const levelsById = useMemo(
    () =>
      levels.reduce((acc: Record<string, Level>, cur) => {
        acc[cur.id] = cur;
        return acc;
      }, {}),
    [levels],
  );
  const categoriesTitles = useMemo(() => {
    return Object.values(PhraseCategory).reduce(
      (acc: { [key in PhraseCategory]?: string }, cur) => {
        acc[cur] = strings[cur as keyof typeof strings] as any;
        return acc;
      },
      {},
    );
  }, [game.lang]);

  useEffect(() => {
    if (!phrase) {
      api.getPhrase(game.levels, commit).then((newPhrase) => {
        setLevel(levelsById[newPhrase.level]);
        setPhrase(newPhrase);
        setCommit(0);
      });
    }
  }, [phrase]);

  const levelTitle = strings[level.id as keyof typeof strings] as string;
  let title = levelTitle;

  if (phrase?.category && phrase.category !== PhraseCategory.BASICS) {
    const categoryTitle = categoriesTitles[phrase.category];

    if (categoryTitle) {
      title = categoryTitle;
    }
  }

  if (phrase?.title) {
    title = phrase.title;
  }

  return (
    <>
      <Header
        actions={[HeaderActionsTypes.AUTO_COMMIT, HeaderActionsTypes.EXIT]}
      />
      <Box
        className="playerList"
        width="100%"
        border="solid 1px"
        borderColor={level?.color || 'esquentaPink'}
        borderRadius="xl"
      >
        <Center borderRadius="inherit">
          <Heading
            fontSize="2.5rem"
            className="playerTitle"
            borderBottom="solid 1px"
            borderColor={level?.color || 'esquentaPink'}
            bgColor={level?.color}
            borderTopRadius="inherit"
            borderBottomRadius="0"
          >
            {title}
          </Heading>
        </Center>
        <Flex direction="column">
          {phrase ? (
            <PhraseCard phrase={phrase} />
          ) : (
            <Button isLoading variant="ghost" width="100%" height="60px" />
          )}
        </Flex>
        <ButtonGroup
          width="100%"
          borderTop="solid 1px"
          borderColor={level?.color || 'esquentaPink'}
          justifyContent="space-between"
          padding="15px 20px"
          position="relative"
        >
          <Button
            borderColor="esquentaPink"
            color="esquentaPink"
            variant="outline"
            leftIcon={buttonIcons.back}
            onClick={() => navigate(Paths.LEVELS)}
            _hover={{
              background: 'esquentaPink',
              color: 'esquentaBlack',
            }}
          >
            {strings.levels}
          </Button>
          {phrase && game.autoCommit && (
            <Button
              variant="ghost"
              width="60%"
              disabled={true}
              _disabled={{ textColor: 'white' }}
            >
              {levelTitle}
            </Button>
          )}
          {!game.autoCommit && (
            <Button
              borderColor="esquentaWhite"
              color="esquentaWhite"
              leftIcon={buttonIcons.refresh}
              isLoading={!phrase}
              onClick={() => setPhrase(null)}
              variant="outline"
              _hover={{
                background: 'esquentaWhite',
                color: 'esquentaBlack',
              }}
            >
              {strings.return}
            </Button>
          )}
          <Button
            borderColor="esquentaBlue"
            color="esquentaBlue"
            leftIcon={buttonIcons.play}
            isLoading={!phrase}
            onClick={() => {
              if (phrase) {
                setCommit(phrase.id);
              }

              setPhrase(null);
            }}
            variant="outline"
            _hover={{
              background: 'esquentaBlue',
              color: 'esquentaBlack',
            }}
          >
            {game.autoCommit ? strings.next : strings.discard}
          </Button>
        </ButtonGroup>
      </Box>
      <Footer />
    </>
  );
}
