import { Box, ButtonGroup, Icon, IconButton, Tooltip } from '@chakra-ui/react';
import BottleCardsIcon from '@components/icons/BottleCardsIcon';
import CardGameIcon from '@components/icons/CardGameIcon';
import ReturnCardsIcon from '@components/icons/ReturnCardsIcon';
import TrashCardsIcon from '@components/icons/TrashCardsIcon';
import { EMPTY_GAME, GameModes, Paths } from '@constants';
import { useGame } from '@context/game';
import ExitIcon from '@icons/ExitIcon';
import getStrings from '@strings';
import EsquentaAPI from '@utils/EsquentaAPI';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';

export enum HeaderActionsTypes {
  AUTO_COMMIT,
  GAME_MODE,
  EXIT,
}

export type HeaderActionsProps = {
  actions: HeaderActionsTypes[];
};

export default function HeaderActions({ actions }: HeaderActionsProps) {
  const { game, setGame } = useGame();
  const navigate = useNavigate();
  const [gameMode, setGameMode] = useState<GameModes>(game.gameMode);
  const [autoCommit, setAutoCommit] = useState<boolean>(game.autoCommit);

  const strings = getStrings(game.lang);

  const quitGame = () => {
    if (!game) return;

    Swal.fire({
      icon: 'question',
      title: strings.exitGameQuestion,
      text: strings.exitGameDescription,
      showDenyButton: true,
      confirmButtonText: strings.exitNo,
      denyButtonText: strings.exitYes,
    }).then((result) => {
      const { isDenied } = result;

      if (isDenied) {
        setGame(EMPTY_GAME);
        navigate(Paths.HOME);
      }
    });
  };

  const toggleGameMode = () => {
    const newGameMode =
      gameMode === GameModes.BLUE_ASKOV
        ? GameModes.CARD_GAME
        : GameModes.BLUE_ASKOV;

    setGameMode(newGameMode);
  };

  useEffect(() => {
    (async () => {
      if (gameMode === game.gameMode && autoCommit === game.autoCommit) {
        return;
      }

      const api = new EsquentaAPI(game);
      const newGame = await api.updateGame(autoCommit, gameMode);

      newGame.levels = gameMode === game.gameMode ? game.levels : [];

      setAutoCommit(newGame.autoCommit);
      setGameMode(newGame.gameMode);
      setGame(newGame);
    })();
  }, [gameMode, autoCommit]);

  return (
    <>
      <Box
        display={{ base: 'block', md: 'block' }}
        className="header-actions-container"
        mt={{ base: 4, md: 0 }}
      >
        <ButtonGroup display="flex">
          {actions.includes(HeaderActionsTypes.AUTO_COMMIT) && (
            <Tooltip
              label={
                game.autoCommit ? strings.autoCommitOn : strings.autoCommitOff
              }
            >
              <IconButton
                aria-label="Exit"
                isRound={true}
                icon={
                  <Icon
                    as={game.autoCommit ? TrashCardsIcon : ReturnCardsIcon}
                  ></Icon>
                }
                maxHeight="60px"
                className="headerAction"
                variant="link"
                onClick={() => setAutoCommit(!autoCommit)}
              />
            </Tooltip>
          )}
          {actions.includes(HeaderActionsTypes.GAME_MODE) && (
            <Tooltip
              label={
                game.gameMode === GameModes.BLUE_ASKOV
                  ? strings.blueAskovMode
                  : strings.cardGameMode
              }
            >
              <IconButton
                aria-label="Exit"
                isRound={true}
                icon={
                  <Icon
                    as={
                      game.gameMode === GameModes.BLUE_ASKOV
                        ? BottleCardsIcon
                        : CardGameIcon
                    }
                  ></Icon>
                }
                maxHeight="60px"
                className="headerAction"
                variant="link"
                bgColor="esquentaAskov"
                onClick={() => toggleGameMode()}
              />
            </Tooltip>
          )}
          {actions.includes(HeaderActionsTypes.EXIT) && (
            <IconButton
              aria-label="Exit"
              isRound={true}
              icon={<Icon as={ExitIcon}></Icon>}
              maxHeight="60px"
              className="headerAction"
              variant="link"
              onClick={() => quitGame()}
            />
          )}
        </ButtonGroup>
      </Box>
    </>
  );
}
