import {
  Box,
  Center,
  Flex,
  Heading,
  Icon,
  Image,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
} from '@chakra-ui/react';
import EsquentaButton from '@components/EsquentaButton';
import { colors, Paths } from '@constants';
import { useGame } from '@context/game';
import logo from '@img/logo.svg';
import getStrings from '@strings';
import { HiOutlineFire } from 'react-icons/hi';
import { IoPlay } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import './home.scss';
import Footer from '../../components/Footer';

export default function Home() {
  const { game } = useGame();
  const navigate = useNavigate();
  const buttonIcon = <Icon as={IoPlay} />;

  const strings = getStrings(game.lang);

  const goToLevelsPage = () => navigate(Paths.LEVELS);

  return (
    <>
      <Flex maxW="1140px" direction="column" className="home">
        <Center>
          <SimpleGrid columns={{ sm: 1, md: 2 }}>
            <Flex className="mainLogo" direction="row" alignItems="center">
              <Flex direction="column" alignContent="center">
                <Image src={logo} />
              </Flex>
            </Flex>
            <Box marginTop="55px">
              <Center>
                <Heading>{strings.howToPlay}</Heading>
              </Center>
              <List className="rules">
                {strings.howToPlaySteps.map((step, index) => (
                  <ListItem className="rule" key={`rule-${index}`}>
                    <ListIcon as={HiOutlineFire} color={colors.esquentaPink} />
                    {step}{' '}
                  </ListItem>
                ))}
              </List>
              <EsquentaButton
                rightIcon={buttonIcon}
                float="right"
                margin="50px"
                onClick={goToLevelsPage}
                onTouchEnd={goToLevelsPage}
                colorType="pink"
              >
                {strings.startGame}
              </EsquentaButton>
            </Box>
          </SimpleGrid>
        </Center>
      </Flex>
      <Footer />
    </>
  );
}
