import { Game } from '@types';

export const colors = {
  esquentaBlue: '#04b9fb',
  esquentaPink: '#eb83b5',
  esquentaGray: '#545863',
  esquentaPepper: '#c1292e',
  esquentaAskov: '#0b76fb',
  esquentaBlack: '#1f1f1f',
  esquentaWhite: '#e5e5e5',
};

export enum Levels {
  ICE_BREAKER = 1,
  MILD = 2,
  WARM = 3,
  HOT = 4,
  VERY_HOT = 5,
  EXTREMELY_HOT = 6,
  INSANELY_HOT = 7,
  HOT_AS_FUCK = 8,
  FINISH_LINE = 9,
}

export enum GameModes {
  BLUE_ASKOV = 'BLUE_ASKOV',
  CARD_GAME = 'CARD_GAME',
}

export const DEFAULT_LANG = 'pt-br';
export const EMPTY_GAME: Game = {
  id: 0,
  lang: DEFAULT_LANG,
  token: '',
  gameMode: GameModes.BLUE_ASKOV,
  autoCommit: true,
  levels: [],
};

export enum PhraseLevel {
  BASICS = 'BASICS',
  BLUE_ASKOV = 'BLUE_ASKOV',
  PEPPER = 'PEPPER',
  THE_GAME = 'THE_GAME',
  NHIE = 'NHIE',
  TOD = 'TOD',
  COUPLE = 'COUPLE',
  DARING = 'DARING',
  KINKY = 'KINKY',
}

export enum PhraseCategory {
  BASICS = 'BASICS',
  DARE = 'DARE',
  GROUP_DARE = 'GROUP_DARE',
  NHIE = 'NHIE',
  NAUGHTY_QUESTION = 'NAUGHTY_QUESTION',
  SAY_FOUR = 'SAY_FOUR',
  HOT_NHIE = 'HOT_NHIE',
  WARMING = 'WARMING',
  SHIT_FRIEND = 'SHIT_FRIEND',
  MASTER_SAID = 'MASTER_SAID',
  GOING_CRAZY = 'GOING_CRAZY',
}

export enum PhraseStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  REPROVED = 'REPROVED',
}

export enum Paths {
  HOME = '/',
  LEVELS = '/levels',
  PLAY = '/play',
}
