import { PhraseCategory, PhraseLevel } from '@constants';

const langDefPtBr = {
  footerPrefix: 'Esse jogo foi desenvolvido com muito',
  and: 'e',
  by: 'por',
  howToPlay: 'Como jogar',
  howToPlaySteps: [
    'Forme uma roda com todos os jogadores e escolha quem começa',
    'Escolha pelo menos um nível para as frases',
    'Todos os níveis podem ser ativados ao mesmo tempo',
    'Inicie o jogo',
    'Ao aparecer a frase, leia em voz alta para todos',
    'O jogador da vez pode fazer o que diz a frase, se quiser. Ou pode pedir uma nova frase',
    'Todos os jogadores participam em Desafios em Grupo, Eu Nunca e Amigo de Merda',
    'NINGUÉM É OBRIGADO A RESPONDER, BEBER OU FAZER ALGO QUE NÃO SE SINTA CONFORTÁVEL',
    'Divirtam-se 😘',
  ],
  startGame: 'Jogar!',
  levelSelector: 'Selecione os Níveis',
  back: 'Voltar',
  next: 'Próxima',
  discard: 'Descartar',
  return: 'Devolver',
  levels: 'Níveis',
  esquenta: 'Esquenta',
  noLevelsAlert: 'Escolha pelo menos um nível para jogar',
  exitGameQuestion: 'Quer realmente sair do jogo?',
  exitGameDescription:
    'Todas as afirmações já vistas ficarão disponíveis de novo',
  exitYes: 'Sim, quero sair :/',
  exitNo: 'Não, vamos jogar :D',
  cardGameMode:
    'A gente pegou os baralhos do "Esquenta, o Jogo" e colocou aqui, é só uma cópia mesmo, hehe',
  blueAskovMode:
    'Nossa versãozinha do Esquenta, com um pouco de vários jogos indecentes',
  autoCommitOn:
    'Todas as cartas que saírem são descartadas automaticamente e não aparecem mais',
  autoCommitOff:
    'Você escolhe quando uma carta deve ser descartada e quando deve voltar para o jogo',
  [PhraseCategory.BASICS]: 'Frases Base',
  [PhraseCategory.DARE]: 'Desafio',
  [PhraseCategory.GROUP_DARE]: 'Desafio em Grupo',
  [PhraseCategory.NHIE]: 'Eu Nunca',
  [PhraseCategory.NAUGHTY_QUESTION]: 'Perguntinha Indecente',
  [PhraseCategory.SAY_FOUR]: 'Diga Quatro',
  [PhraseCategory.HOT_NHIE]: 'Eu Nunca',
  [PhraseCategory.WARMING]: 'Esquentando o Clima',
  [PhraseCategory.SHIT_FRIEND]: 'Amigo de Merda',
  [PhraseCategory.MASTER_SAID]: 'O Mestre Mandou',
  [PhraseCategory.GOING_CRAZY]: 'Metendo o Louco',
  [PhraseLevel.BASICS]: 'O Básico 🧨',
  [PhraseLevel.BLUE_ASKOV]: 'Askov Azul 🔥',
  [PhraseLevel.PEPPER]: 'Pimentinha 🌶',
  [PhraseLevel.THE_GAME]: 'O Básico 🧨',
  [PhraseLevel.TOD]: 'Verdade ou desafio?',
  [PhraseLevel.COUPLE]: 'Casal',
  [PhraseLevel.DARING]: 'Ousadia',
  [PhraseLevel.KINKY]: 'Pesadão',
};

export default langDefPtBr;
