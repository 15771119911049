import { GameModes, PhraseLevel } from '@constants';
import { Game, Level, Phrase } from '@types';
import Axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import RequestException from './RequestException';

const baseURL = <string>process.env.REACT_APP_API_HOST;

export default class EsquentaAPI {
  private client: AxiosInstance;

  constructor({ token }: Game) {
    const client = Axios.create({
      baseURL: `${baseURL}/game`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      validateStatus: () => true,
    });

    client.interceptors.response.use(
      (response) => response,
      (error) => {
        this.handleAxiosError(error);
      },
    );

    this.client = client;
  }

  private handleAxiosError(error: AxiosError) {
    let errorMessage = 'An error ocurred while setting up the API request';
    let statusCode = 0;
    if (error.response) {
      errorMessage = error.response.data.message;
      statusCode = error.response.status;
    } else if (error.request) {
      errorMessage = 'No response was received from API';
    }

    throw new RequestException(errorMessage, statusCode);
  }

  static async createGame(lang = 'pt-br') {
    const url = `${baseURL}/game`;

    try {
      const { data: game } = await Axios.post<Game>(url, { lang });

      return game;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async updateLevel(level: PhraseLevel) {
    const response = await this.client.put<Game>(`/`, { level });

    return this.validateResponse<Game>(response);
  }

  async updateGame(autoCommit: boolean, gameMode: GameModes) {
    const response = await this.client.put<Game>(`/`, {
      autoCommit,
      gameMode,
    });

    return this.validateResponse<Game>(response);
  }

  async getGame() {
    const response = await this.client.get<Game>(`/`);

    return this.validateResponse<Game>(response);
  }

  static async getLevels(gameMode: GameModes) {
    const url = `${baseURL}/level`;

    try {
      const { data: game } = await Axios.get<Level[]>(url, {
        params: { gameMode },
      });

      return game;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async resetGame() {
    const response = await this.client.post<Game>('/reset');

    const { game, players } = this.validateResponse<any>(response);

    game.players = players;

    return game as Game;
  }

  async getPhrase(gameLevels: PhraseLevel[], commit: number) {
    const levels = gameLevels.join(';');
    const response = await this.client.get<Phrase>(`/phrase`, {
      params: { levels, commit },
    });

    return this.validateResponse<Phrase>(response);
  }

  private validateResponse<T>(response: AxiosResponse): T {
    if (response.status > 199 && response.status < 300) {
      return response.data;
    }

    const error: any = new Error(
      'An error occurred while communicating with Esquenta Core',
    );
    error.response = response;

    console.log('RES status:', response.status);
    console.log('RES data:', response.data);

    throw error;
  }
}
